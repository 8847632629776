import React, { useEffect, useRef } from "react"
import { Link } from "gatsby"
import BGImage from "gatsby-background-image"
import styled from "styled-components"

import { H1White, buttonThree } from "../../styles/helpers"
import { createSlug } from "../../utils/helperFunc"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const HeroSection = styled.section`
  overflow: hidden;
  .hero-wrapper {
    position: relative;
    min-height: 60rem;

    @media (min-width: 768px) {
      min-height: 75rem;
      height: 100vh;
    }

    @media (min-width: 1025px) {
      min-height: 100vh;
    }
  }

  .background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 5;
  }

  .hero-content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    padding: 4rem;
    transform: translate(-50%, -50%);
    z-index: 10;

    @media (min-width: 768px) {
      max-width: 70vw;
      padding: 2rem;
    }

    @media (min-width: 1025px) {
      max-width: 90rem;
      padding: 2rem;
    }

    &__title {
      h2 {
        ${H1White};
        margin: 0;
        text-transform: uppercase;
      }
    }

    p {
      ${H1White};
      margin: 0;
    }

    .hero-button {
      margin-top: 3rem;

      a {
        ${buttonThree};
      }
    }
  }

  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    @media (min-width: 1025px) {
    }

    div {
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`

const Hero = props => {
  const {
    hero: { acf: hero },
  } = props

  const heroFluid = hero._coo_hohr_bg.localFile
    ? hero._coo_hohr_bg.localFile.childImageSharp.fluid
    : null
  const heroContent = hero._coo_hohr_content
  const heroTitle = hero._coo_hohr_title

  const trigger = useRef(null)
  const content = useRef(null)
  const imageWrap = useRef(null)

  useEffect(() => {
    const tl = gsap.timeline()
    tl.add("start")
      .to(
        content.current,
        { y: -75, autoAlpha: 0.25, duration: 1, ease: "none" },
        "start"
      )
      .to(
        imageWrap.current,
        { scale: 1.1, y: 100, duration: 1, ease: "none" },
        "start"
      )

    ScrollTrigger.create({
      animation: tl,
      trigger: trigger.current,
      markers: false,
      scrub: true,
      start: "top 0%",
    })
  }, [])

  return (
    <HeroSection ref={trigger}>
      <div className="hero-wrapper">
        <div ref={content} className="hero-content">
          <div className="hero-content__title">
            <h2>{heroTitle}</h2>
          </div>
          <div dangerouslySetInnerHTML={{ __html: heroContent }} />
          <div className="hero-content__filter" />
        </div>
        <div ref={imageWrap} className="background-image">
          <BGImage tag="div" fluid={heroFluid} />
        </div>
        <div className="background-overlay" />
      </div>
    </HeroSection>
  )
}

export default Hero
