import React, { useState } from "react"
import styled from "styled-components"

import {
  medWrapper,
  colors,
  H3Green,
  buttonThree,
  B1Black,
} from "../../styles/helpers"
import GalleryDisplay from "./GalleryDisplay"

const GalleriesSection = styled.section`
  padding: 0 0 2.5rem;

  &::after {
    content: "";
    display: table;
    clear: both;
  }

  .catSelect {
    &__title {
      width: 100%;
      text-align: center;

      h2 {
        ${H3Green};
      }

      p {
        ${B1Black};
      }
    }

    &__button {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      button {
        ${buttonThree};
        min-width: 30rem;
        display: inline-block;
        margin: 0.5rem 2rem;

        @media (max-width: 767px) {
          padding-top: 1rem;
          padding-bottom: 1rem;
        }

        @media (min-width: 768px) {
          min-width: auto;
          margin: 2rem;
        }
      }

      button.activeBtn {
        background-color: rgba(197, 231, 222, 1);
        color: ${colors.greyBrown};
      }
    }
  }

  .wrapper {
    ${medWrapper};
  }

  .gallery-wrapper {
    display: block;
    position: relative;
    min-height: 1px;
    width: 100%;
    max-width: 100%;
    margin: auto;
    padding: 0;
    overflow: auto;
  }
`

const Galleries = ({ data }) => {
  const [galActive, changeGal] = useState("Homes")
  const galleries = data.acf._coo_gallery_images

  if (galActive === "") {
    changeGal(galleries[1].category)
  }
  return (
    <GalleriesSection>
      <div className="catSelect">
        <div className="wrapper">
          <div className="catSelect__title">
            <h2>Gallery</h2>
            <p>Choose a gallery below to explore our community.</p>
          </div>
          <div className="catSelect__button">
            {galleries.map((gal, index) => {
              return (
                <div key={index}>
                  <button
                    type="button"
                    className={`${
                      gal.category === galActive ? "activeBtn" : ""
                    }`}
                    onClick={e => {
                      changeGal(gal.category)
                    }}
                  >
                    {gal.category}
                  </button>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="gallery-wrapper">
        {galleries.map((gallery, index) => {
          const galleryToReturn =
            galActive === gallery.category ? (
              <GalleryDisplay key={index} gallery={gallery} />
            ) : (
              <div key={index} />
            )
          return galleryToReturn
        })}
      </div>
    </GalleriesSection>
  )
}

export default Galleries
