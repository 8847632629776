import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"

import Hero from "../components/Gallery/Hero"
import Galleries from "../components/Gallery/Galleries"

const Gallery = props => {
  const { seoInfo, hero, galleries } = props.data
  const location = props.location.pathname
  return (
    <Layout location={location}>
      <SEO
        title={seoInfo.acf._swb_theme_meta_title}
        description={seoInfo.acf._swb_theme_description}
        metaImg={seoInfo.acf._swb_theme_image.localFile.publicURL}
        location={props.location.pathname}
      />
      <Hero hero={hero} />
      <Galleries data={galleries} />
    </Layout>
  )
}

export const galleryQuery = graphql`
  query galleryPage($id: Int!) {
    seoInfo: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _swb_theme_meta_title
        _swb_theme_description
        _swb_theme_image {
          localFile {
            publicURL
          }
        }
      }
    }

    hero: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_hohr_bg {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        _coo_hohr_title
        _coo_hohr_content
      }
    }

    galleries: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_gallery_images {
          category
          gallery {
            alt_text
            localFile {
              publicURL
              childImageSharp {
                fluid(maxWidth: 1500) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
                original {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Gallery
