import React from "react"
import ClipLoader from "react-spinners/ClipLoader"

const SpinnerAnimation = () => {
  return (
    <>
      <ClipLoader size={35} color={"#008675"} loading={true} />
    </>
  )
}

export default SpinnerAnimation
