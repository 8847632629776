import React, { useState, useEffect } from "react"
import Img from "gatsby-image"
import BGImage from "gatsby-background-image"
import InfiniteScroll from "react-infinite-scroller"
import styled from "styled-components"

import { colors, medWrapper, fontSizer } from "../../styles/helpers"
import GallerySlider from "./GallerySlider"
import SpinnerAnimation from "./SpinnerAnimation"

const GalleryContainer = styled.div`
  position: relative;
  padding-top: 0;
  padding-bottom: 5rem;

  @media (min-width: 768px) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .loader {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100% !important;
    height: 10rem;
    text-align: center;
  }

  .wrapper {
    ${medWrapper};
    overflow: hidden;
  }

  .galleyWrap {
    width: 100%;
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 1rem;
    grid-auto-rows: minmax(250px, auto);
    grid-auto-flow: dense;

    @media (min-width: 768px) {
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
      grid-auto-rows: minmax(350px, auto);
    }

    @media (min-width: 1025px) {
      padding-bottom: 15rem;
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
      grid-auto-rows: minmax(350px, auto);
    }
  }

  .galleryImage {
    position: relative;
    width: 100%;
    height: 35rem;
    margin: auto;
    cursor: pointer;

    &__image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      width: 100%;
      transition: all 0.4s ease-out;
      overflow: hidden;

      &--wrap {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        transform: scale(1);
        transition: all 0.4s ease-out;

        div {
          position: absolute !important;
          top: 0 !important;
          left: 0 !important;
          width: 100% !important;
          height: 100% !important;
          display: block !important;
        }
      }
    }

    &__hoverState {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: all 0.4s ease-out;
      background-color: rgba(0, 134, 117, 0.6);
      opacity: 0;

      p {
        ${fontSizer(5, 10, 76.8, 150, 2.6)};
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
        color: ${colors.white};
      }
    }

    &:hover {
      .galleryImage__image {
        .galleryImage__image--wrap {
          transform: scale(1.1);
        }
      }
      .galleryImage__hoverState {
        opacity: 0.99;
      }
    }
  }
`

const GalleryDisplay = ({ gallery }) => {
  const IMAGES_TO_LOAD = 10
  const [isSliderActive, setIsSliderActive] = useState(false)
  const [clikcedImage, setClikcedImage] = useState(0)

  const [galleryImages, setGalleryImages] = useState({
    allImageFiles: [],
    totalImages: 0,
    index: 0,
    hasMore: false,
    displayedImages: [],
  })

  useEffect(() => {
    setGalleryImages(prevState => {
      return {
        ...prevState,
        allImageFiles: gallery?.gallery,
        totalImages: gallery?.gallery?.length || [],
        index: IMAGES_TO_LOAD,
        hasMore: gallery?.gallery?.length > IMAGES_TO_LOAD,
        displayedImages: gallery?.gallery?.slice(0, IMAGES_TO_LOAD),
      }
    })
  }, [])

  const loadFunc = () => {
    setTimeout(() => {
      setGalleryImages(prevState => {
        const nextImages = prevState.allImageFiles.slice(
          prevState.index,
          prevState.index + IMAGES_TO_LOAD
        )

        return {
          ...prevState,
          index: prevState.index + IMAGES_TO_LOAD,
          hasMore: prevState.totalImages > prevState.index + IMAGES_TO_LOAD,
          displayedImages: [...prevState.displayedImages, ...nextImages],
        }
      })
    }, 1000)
  }

  return (
    <GalleryContainer id="a">
      <div className="wrapper">
        <InfiniteScroll
          className="galleyWrap"
          loadMore={loadFunc}
          hasMore={galleryImages.hasMore}
          threshold={90}
          loader={
            <div className="loader" key={`galleryLoader`}>
              <div>
                <SpinnerAnimation />
              </div>
              <p>Loading ...</p>
            </div>
          }
        >
          {galleryImages.displayedImages.map((img, index) => {
            return (
              <div
                onClick={() => {
                  setIsSliderActive(true)
                  setClikcedImage(index)
                }}
                className="galleryImage"
                key={index}
              >
                <div className="galleryImage__image">
                  <div className="galleryImage__image--wrap">
                    {img?.localFile && (
                      <BGImage
                        fluid={img?.localFile?.childImageSharp?.fluid}
                        tag="div"
                      />
                    )}
                  </div>
                </div>
                <div className="galleryImage__hoverState">
                  <p>&#43;</p>
                </div>
              </div>
            )
          })}
        </InfiniteScroll>
      </div>

      {isSliderActive && (
        <GallerySlider
          images={gallery.gallery}
          setIsSliderActive={setIsSliderActive}
          clikcedImage={clikcedImage}
        />
      )}
    </GalleryContainer>
  )
}

export default GalleryDisplay
